<template>
  <receipt-pricing class="receipt">
    <finish-actions />
  </receipt-pricing>
</template>

<script>
  import {mapGetters, mapState} from 'vuex';
  import ReceiptPricing
    from '@/components/assignment_components/one_assignment/interactive_info/shared_components/ReceiptPricing';
  import FinishActions
    from '@/components/assignment_components/one_assignment/interactive_info/pt_actions/FinishActions';

  export default {
    components: {
      'receipt-pricing': ReceiptPricing,
      'finish-actions': FinishActions
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userIsInterpreter']),
      ...mapGetters('AssignmentFlowStore', ['invoiceFinalData']),
      ...mapState('OneAssignmentStore', {
        assignmentObj: (state) => state.storeJobObj || {}
      })
    }
  };
</script>

<style scoped>
  .receipt {
    width: 100%;
    background-color: #fff;
  }
</style>
